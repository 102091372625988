// src/components/Chat.tsx
import React, { useEffect, useState } from "react";
import OpenAI from "openai";
import { Widget, addResponseMessage } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import "./styles.css";
import logo from './avatar.png';



const Chat: React.FC = () => {
  const [assistant, setAssistant] = useState<any>(null);
  const [thread, setThread] = useState<any>(null);
  const [openai, setOpenai] = useState<any>(null);

  useEffect(() => {
    initChatBot();
    addResponseMessage("Hi Expert! I'm here to assist you in planning trips. Ask me anything: destination info, itinerary suggestions, quotes etc.");
  }, []);


  const initChatBot = async () => {
    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });
    // Retrieve the assistant
    const assistant = await openai.beta.assistants.retrieve(process.env.REACT_APP_ASSISTANT_ID);
    // Create a thread
    const thread = await openai.beta.threads.create();

    setOpenai(openai);
    setAssistant(assistant);
    setThread(thread);
  };

  const handleNewUserMessage = async(newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Send a message to the thread
    await openai.beta.threads.messages.create(thread.id, {
      role: "user",
      content: newMessage,
    });
    // Run the assistant
    const run = await openai.beta.threads.runs.create(thread.id, {
      assistant_id: assistant.id,
    });
    // Create a response
    let response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    // Wait for the response to be ready
    while (response.status === "in_progress" || response.status === "queued") {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      response = await openai.beta.threads.runs.retrieve(thread.id, run.id);
    }


    // Get the messages for the thread
    const messageList = await openai.beta.threads.messages.list(thread.id);

    // Find the last message for the current run
    const lastMessage = messageList.data
      .filter((message: any) => message.run_id === run.id && message.role === "assistant")
      .pop();

    // Print the last message coming from the assistant
    if (lastMessage) {
      console.log(lastMessage.content[0]["text"].value);
      addResponseMessage(lastMessage.content[0]["text"].value);
    }
  };

  // const handleToggle = async (status) => {
  //   console.log("toggled to "+status)
  // };
  return (
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        profileAvatar={logo}
        titleAvatar={logo}
        title="Expert Buddy"
        subtitle=""
        senderPlaceHolder="Ask for question"
        // handleToggle={handleToggle}
      />
  );
};

export default Chat;
